import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import GroupIcon from '@material-ui/icons/Group';
import House from '@material-ui/icons/House';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import MapIcon from '@material-ui/icons/Map';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import PlaceIcon from '@material-ui/icons/Place';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import StorefrontIcon from '@material-ui/icons/Storefront';
import React from 'react';
import {Redirect} from 'react-router-dom';
import FirebaseAuthApp from '../../firebase-auth-app/FirebaseAuthApp';
import MainLayout from '../../layouts/main';
import CustomClaim from '../../models/scoopm/CustomClaim';
import deviceSchema from '../../schemas/wavem/Device';
import invoiceFormulaSchema from '../../schemas/wavem/InvoiceFormula';
import panicEventSchema from '../../schemas/wavem/PanicEvent';
import payoutFormulaSchema from '../../schemas/wavem/PayoutFormula';
import placeSchema from '../../schemas/wavem/Place';
import regionSchema from '../../schemas/wavem/Region';
import serviceAreaSchema from '../../schemas/wavem/ServiceArea';
import tripSourceDetailschema from '../../schemas/wavem/TripSourceDetail';
import tripTemplateSchema from '../../schemas/wavem/TripTemplate';
import userGroupSchema from '../../schemas/wavem/UserGroup';
import vehicleSchema from '../../schemas/wavem/Vehicle';
import vendorSchema from '../../schemas/wavem/Vendor';
import vendorApplicationSchema from '../../schemas/wavem/VendorApplication';
import {AboutPage} from '../../unauth-app/About';
import Credits from '../../unauth-app/Credits';
import {PrivacyPolicy} from '../../unauth-app/PrivacyPolicy';
import {ShareMyRide} from '../../unauth-app/ShareMyRide/ShareMyRide';
import {TermsAndConditionsPage} from '../../unauth-app/TermsAndConditions';
import Admin from '../admin';
import Zendrive from '../admin/Zendrive';
import ApiDashboard from '../admin/api-dashboard';
import AllCoupons from '../admin/coupon-codes/All';
import Coupon from '../admin/coupon-codes/Coupon';
import Dashboard from '../admin/dashboard';
import Directions from '../admin/directions/Directions';
import DriverEarningsDLogs from '../admin/driver-earnings-d-logs';
import AllowPayoutDrivers from '../admin/drivers/AllowPayoutDrivers';
import ApprovedDrivers from '../admin/drivers/ApprovedDrivers';
import AtlRideshareCsv from '../admin/drivers/AtlRideshareCsv';
import AutoCalculateDelayedEarningsDrivers from '../admin/drivers/AutoCalculateDelayedEarningsDrivers';
import AutoCalculateInstantEarningsDrivers from '../admin/drivers/AutoCalculateInstantEarningsDrivers';
import ClutchDrivers from '../admin/drivers/ClutchDrivers';
import DriversMileage from '../admin/drivers/DriversMileage';
import Feedback from '../admin/drivers/Feedback';
import Files from '../admin/drivers/Files';
import FountainDrivers from '../admin/drivers/FountainDrivers';
import GetSignUpLink from '../admin/drivers/GetSignUpLink';
import IncompleteApplications from '../admin/drivers/IncompleteApplications';
import RejectedDrivers from '../admin/drivers/RejectedDrivers';
import Reports from '../admin/drivers/Reports';
import SubmittedApplications from '../admin/drivers/SubmittedApplications';
import SuspendedDrivers from '../admin/drivers/SuspendedDrivers';
import {CustomerJobs, DriverJobs} from '../admin/drivers/UserJobs';
import {Driver} from '../admin/drivers/driver';
import AllEventsOutgoing from '../admin/events-outgoing/All';
import EventOutgoing from '../admin/events-outgoing/Event';
import EzCaterEventsOutgoing from '../admin/events-outgoing/EzCater';
import AllEvents from '../admin/events/All';
import Event from '../admin/events/Event';
import EzCaterEvents from '../admin/events/EzCater';
import Gatekeeper from '../admin/gatekeeper';
import Geofences from '../admin/geofences';
import EzCaterMap from '../admin/jobs/EzCaterMap';
import Find from '../admin/jobs/Find';
import JobsMap from '../admin/jobs/JobsMap';
import JobsTable from '../admin/jobs/JobsTable';
import withRideshareJobs from '../admin/jobs/RideshareJobs';
import StatusGroup from '../admin/jobs/StatusGroup';
import UniversalView from '../admin/jobs/universal-view';
import Notifications from '../admin/notifications';
import {TripLogs} from '../admin/object-logs';
import TripLog from '../admin/object-logs/TripLog';
import Payouts from '../admin/payouts';
import AllQuotes from '../admin/quotes/All';
import OloQuotes from '../admin/quotes/Olo';
import Quote from '../admin/quotes/Quote';
import AddBucket from '../admin/rides/AddBucket';
import CreateRide from '../admin/rides/CreateRide';
import Marketplace from '../admin/rides/Marketplace';
import MarketplaceList from '../admin/rides/MarketplaceList';
import Ride from '../admin/rides/Ride';
import RideRedirect from '../admin/rides/RideRedirect';
import RideWithoutCustomerId from '../admin/rides/RideWithoutCustomerId';
import Dashcam from '../admin/streaming/dashcam';
import TripTypes from '../admin/trip-types';
import AllUsers from '../admin/users/All';
import CSV from '../admin/users/CSV';
import CreateUser from '../admin/users/Create';
import SuperAdmins from '../admin/users/SuperAdmins';
import User from '../admin/users/User';
import ForgotPassword from '../auth/ForgotPassword';
import LinkFountainApplication from '../auth/LinkFountainApplication';
import SignIn from '../auth/SignIn';
import SignUp from '../auth/SignUp';
import Dispatch from '../dispatch';
import ActiveDispatch from '../dispatch/Active';
import DispatchForm from '../dispatch/Form';
import History from '../dispatch/History';
import DispatchTrip from '../dispatch/Trip';
import EarningsRefresh from '../earnings/Refresh';
import EarningsReturn from '../earnings/Return';
import Home from '../home';
import CateringDelivery from '../catering/CateringDelivery'
import RideshareLeadGen from '../rideshare/RideshareLeadGen'
import Jobs from '../jobs';
import Chat from '../jobs/Chat';
import JobHistory from '../jobs/History';
import Job from '../jobs/Job';
import RideForm from '../jobs/create/RideForm';
import Openings from '../openings';
import Pricing from '../pricing';
import Profile from '../profile';
import UserGroupInvite from '../user-group-invites/UserGroupInvite';
import CreateVendor from '../vendors/Create';
import FirestoreSchemaRoute from './FirestoreSchemaRoute';
import RealtimeDatabaseSchemaRoute from './RealtimeDatabaseSchemaRoute';
import Route from './Route';

const root = new Route({
  children: [
    new Route({
      name: 'Admin Portal',
      path: 'admin',
      Component: Admin,
      children: [
        new Route({
          name: 'Home',
          Icon: House,
          Component: Dashboard,
          exact: true,
        }),
        new Route({
          name: 'API Dashboard',
          path: 'api',
          requiredClaims: [CustomClaim.manageApi],
          Icon: ListAltIcon,
          Component: ApiDashboard,
          exact: true,
        }),
        new Route({
          name: 'Driver rate logs',
          path: 'driver-earnings-d-logs',
          requiredClaims: [CustomClaim.overridePayout],
          Component: DriverEarningsDLogs,
          hideFromMenu: true,
        }),
        new Route({
          path: 'trip-logs',
          requiredClaims: [CustomClaim.manageApi],
          hideFromMenu: true,
          children: [
            new Route({
              name: 'Log',
              path: ':objectId',
              Component: TripLog,
              hideFromMenu: true,
            }),
            new Route({
              name: 'Logs',
              Component: TripLogs,
              hideFromMenu: true,
            }),
          ],
        }),
        new Route({
          name: 'Drivers',
          path: 'drivers',
          Icon: PeopleIcon,
          requiredClaims: [CustomClaim.manageDrivers],
          children: [
            new Route({
              name: 'Incomplete applications',
              path: 'incomplete-submissions',
              Component: IncompleteApplications,
            }),
            new Route({
              name: 'Submitted',
              path: 'applicants',
              Component: SubmittedApplications,
              separator: true,
            }),
            new Route({
              name: 'Approved (all)',
              path: 'approved',
              Component: ApprovedDrivers,
            }),
            new Route({
              name: 'Approved (Fountain)',
              path: 'approved-fountain',
              Component: FountainDrivers,
            }),
            new Route({
              name: 'Rejected',
              path: 'rejected',
              Component: RejectedDrivers,
            }),
            new Route({
              name: 'Suspended',
              path: 'suspended',
              Component: SuspendedDrivers,
            }),
            new Route({
              name: 'Clutch rate',
              path: 'clutch',
              Component: ClutchDrivers,
              separator: true,
            }),
            new Route({
              name: 'Auto instant earnings',
              path: 'auto-instant-earnings',
              Component: AutoCalculateInstantEarningsDrivers,
              separator: true,
            }),
            new Route({
              name: 'Auto delayed earnings',
              path: 'auto-delayed-earnings',
              Component: AutoCalculateDelayedEarningsDrivers,
            }),
            new Route({
              name: 'Allow instant payout',
              path: 'allow-instant-payout',
              Component: AllowPayoutDrivers,
            }),
            new Route({
              name: 'Get sign-up link',
              path: 'sign-up-link',
              Component: GetSignUpLink,
              separator: true,
            }),
            new Route({
              name: 'ATL Rideshare CSV',
              path: 'reports/atl-rideshare-csv',
              Component: AtlRideshareCsv,
              hideFromMenu: true,
            }),
            new Route({
              name: 'Drivers/mileage',
              path: 'reports/drivers-mileage',
              Component: DriversMileage,
              hideFromMenu: true,
            }),
            new Route({
              path: 'atl-rideshare-csv',
              Component: AtlRideshareCsv,
              // Component: () => <Redirect to="/admin/reports/atl-rideshare-csv" />,
              hideFromMenu: true,
            }),
            new Route({
              name: 'Reports',
              path: 'reports',
              exact: true,
              Component: Reports,
            }),
            new Route({
              path: 'feedback/:id',
              Component: Feedback,
              hideFromMenu: true,
            }),
            new Route({
              path: ':uid/files',
              Component: Files,
              hideFromMenu: true,
            }),
            new Route({
              path: ':uid',
              Component: Driver,
              hideFromMenu: true,
            }),
          ],
        }),
        new Route({
          name: 'Jobs',
          path: 'jobs',
          requiredClaims: [CustomClaim.manageTrips],
          Icon: EmojiTransportationIcon,
          children: [
            new Route({
              path: 'all',
              name: 'Table',
              Component: JobsTable,
            }),
            new Route({
              path: 'map',
              name: 'Map',
              Component: JobsMap,
            }),
            new Route({
              path: 'find',
              name: 'Find',
              Component: Find,
            }),
            new Route({
              path: 'direct-partners',
              name: 'Direct partners',
              Component: UniversalView,
            }),
            new Route({
              path: 'rideshare-unaccepted',
              name: 'Rideshare unaccepted',
              Component: withRideshareJobs(StatusGroup.unaccepted),
            }),
            new Route({
              path: 'rideshare-underway',
              name: 'Rideshare underway',
              Component: withRideshareJobs(StatusGroup.underway),
            }),
            new Route({
              path: 'rideshare-up-next',
              name: 'Rideshare up next',
              Component: withRideshareJobs(StatusGroup.upNext),
            }),
            new Route({
              path: 'rideshare-underway-and-up-next',
              name: 'Rideshare u,u,un',
              Component: withRideshareJobs(StatusGroup.underwayAndUpNext),
            }),
            // new Route({
            //   path: 'rideshare-map',
            //   name: 'ASAP today map',
            //   Component: RideshareJobsMap,
            // }),
            new Route({
              path: 'create',
              name: 'Manual entry',
              Component: CreateRide,
              separator: true,
            }),
            new Route({
              path: 'driver/:id',
              name: 'Driver jobs',
              Component: DriverJobs,
              hideFromMenu: true,
            }),
            new Route({
              path: 'customer/:id',
              name: 'Customer jobs',
              Component: CustomerJobs,
              hideFromMenu: true,
            }),
            new Route({
              path: ':customerId/:tripId/ez-cater-map',
              hideFromMenu: true,
              Component: EzCaterMap,
            }),
            new Route({
              path: ':customerId/:tripId',
              hideFromMenu: true,
              Component: Ride,
            }),
            new Route({
              path: ':tripId',
              hideFromMenu: true,
              Component: RideWithoutCustomerId,
            }),
            new Route({
              // Fallback for old URLs:
              name: 'All',
              hideFromMenu: true,
              Component: JobsTable,
            }),
          ],
        }),
        // new Route({
        //     path: 'invoices',
        //     name: 'Invoices',
        //     Icon: ReceiptIcon,
        //     requiredClaims: [CustomClaim.manageFinancial],
        //     children: [],
        // }),
        new Route({
          name: 'Trips',
          path: 'trips',
          hideFromMenu: true,
          children: [
            new Route({
              path: ':customerId/:tripId',
              hideFromMenu: true,
              Component: RideRedirect,
            }),
            new Route({
              path: ':tripId',
              hideFromMenu: true,
              Component: RideWithoutCustomerId,
            }),
          ],
        }),
        new Route({
          name: 'Rides/tasks',
          path: 'rides',
          requiredClaims: [CustomClaim.manageTrips],
          Icon: EmojiTransportationIcon,
          children: [
            new Route({
              path: 'add-bucket',
              name: 'Add new bucket',
              separator: true,
              Component: AddBucket,
            }),
            new Route({
              path: 'create',
              name: 'Manual entry',
              Component: CreateRide,
            }),
            new Route({
              path: 'marketplace',
              name: 'Marketplace',
              separator: true,
              Component: Marketplace,
            }),
            new Route({
              path: 'marketplace-list',
              name: 'Marketplace list',
              Component: MarketplaceList,
            }),
            new Route({
              path: ':customerId/:tripId/dispatch',
              hideFromMenu: true,
              Component: ({match}) => <Redirect to={`/admin/jobs/${match.params.customerId}/${match.params.tripId}`} />,
            }),
            new Route({
              path: ':customerId/:tripId',
              hideFromMenu: true,
              Component: RideRedirect,
            }),
            new Route({
              path: ':tripId',
              hideFromMenu: true,
              Component: RideWithoutCustomerId,
            }),
          ],
        }),
        new FirestoreSchemaRoute({
          schema: tripTemplateSchema,
          Icon: EmojiTransportationIcon,
          requiredClaims: [CustomClaim.manageLogistics],
        }),
        new Route({
          name: 'Users',
          path: 'users',
          requiredClaims: [CustomClaim.manageUsers],
          Icon: PersonIcon,
          children: [
            new Route({
              name: 'All',
              exact: true,
              Component: AllUsers,
            }),
            new Route({
              path: 'admins',
              name: 'Super admins',
              Component: SuperAdmins,
            }),
            new Route({
              path: 'csv',
              name: 'Download CSV',
              Component: CSV,
            }),
            new Route({
              path: 'create',
              name: 'Add customer',
              Component: CreateUser,
            }),
            new Route({
              path: ':uid',
              hideFromMenu: true,
              Component: User,
            }),
          ],
        }),
        new FirestoreSchemaRoute({
          schema: userGroupSchema,
          Icon: GroupIcon,
          requiredClaims: [CustomClaim.manageUsers],
        }),
        new FirestoreSchemaRoute({
          schema: panicEventSchema,
          Icon: PlaceIcon,
          requiredClaims: [CustomClaim.manageLogistics],
          allowAdding: false,
        }),
        new FirestoreSchemaRoute({
          schema: placeSchema,
          Icon: PlaceIcon,
          requiredClaims: [CustomClaim.manageLogistics],
        }),
        new FirestoreSchemaRoute({
          schema: regionSchema,
          Icon: MapIcon,
          requiredClaims: [CustomClaim.manageLogistics],
        }),
        new FirestoreSchemaRoute({
          schema: serviceAreaSchema,
          Icon: MapIcon,
          requiredClaims: [CustomClaim.manageLogistics],
        }),
        new FirestoreSchemaRoute({
          schema: vendorApplicationSchema,
          Icon: StorefrontIcon,
          requiredClaims: [CustomClaim.manageLogistics],
        }),
        new FirestoreSchemaRoute({
          schema: vendorSchema,
          Icon: StorefrontIcon,
          requiredClaims: [CustomClaim.manageLogistics],
        }),
        new FirestoreSchemaRoute({
          schema: vehicleSchema,
          Icon: DirectionsCarIcon,
          requiredClaims: [CustomClaim.manageDrivers],
        }),
        new FirestoreSchemaRoute({
          schema: invoiceFormulaSchema,
          Icon: ReceiptIcon,
          requiredClaims: [CustomClaim.manageFinancial],
        }),
        new FirestoreSchemaRoute({
          schema: payoutFormulaSchema,
          Icon: AccountBalanceIcon,
          requiredClaims: [CustomClaim.manageFinancial],
        }),
        new Route({
          path: 'payouts',
          name: 'Payouts',
          requiredClaims: [CustomClaim.manageFinancial],
          Icon: AccountBalanceIcon,
          children: [
            new Route({
              name: 'Global switch',
              exact: true,
              Component: Payouts,
            }),
          ],
        }),
        new Route({
          path: 'coupon-codes',
          name: 'Coupon codes',
          requiredClaims: [CustomClaim.manageFinancial],
          Icon: LoyaltyIcon,
          children: [
            new Route({
              name: 'All',
              exact: true,
              Component: AllCoupons,
            }),
            new Route({
              name: 'Coupon code',
              path: ':couponCode',
              hideFromMenu: true,
              Component: Coupon,
            }),
          ],
        }),
        new FirestoreSchemaRoute({
          schema: tripSourceDetailschema,
          Icon: SettingsInputComponentIcon,
          requiredClaims: [CustomClaim.manageFinancial],
        }),
        new Route({
          name: 'Quotes',
          path: 'quotes',
          requiredClaims: [CustomClaim.manageFinancial],
          Icon: ReceiptIcon,
          children: [
            new Route({
              name: 'All',
              exact: true,
              Component: AllQuotes,
            }),
            new Route({
              path: 'olo',
              name: 'OLO',
              Component: OloQuotes,
            }),
            new Route({
              path: ':quoteId',
              hideFromMenu: true,
              Component: Quote,
            }),
          ],
        }),
        new RealtimeDatabaseSchemaRoute({
          schema: deviceSchema,
          Icon: AccountBalanceIcon,
          requiredClaims: [CustomClaim.manageApi],
        }),
        new Route({
          name: 'Events',
          path: 'events',
          requiredClaims: [CustomClaim.manageApi],
          Icon: ListAltIcon,
          children: [
            new Route({
              name: 'All',
              exact: true,
              Component: AllEvents,
            }),
            new Route({
              path: 'ez-cater',
              name: 'ezCater',
              Component: EzCaterEvents,
            }),
            new Route({
              path: ':eventId',
              hideFromMenu: true,
              Component: Event,
            }),
          ],
        }),
        new Route({
          path: 'events-outgoing',
          name: 'Events outgoing',
          requiredClaims: [CustomClaim.manageApi],
          Icon: ListAltIcon,
          children: [
            new Route({
              name: 'All',
              exact: true,
              Component: AllEventsOutgoing,
            }),
            new Route({
              path: 'ez-cater',
              name: 'ezCater',
              Component: EzCaterEventsOutgoing,
            }),
            new Route({
              path: ':eventId',
              hideFromMenu: true,
              Component: EventOutgoing,
            }),
          ],
        }),
        new Route({
          name: 'Other',
          Icon: MoreHorizIcon,
          children: [
            new Route({
              exact: true,
              hideFromMenu: true,
              path: 'directions/:directionsId',
              requiredClaims: [CustomClaim.manageApi],
              Component: Directions,
            }),
            new Route({
              path: 'geofences/:geofenceId?',
              link: 'geofences',
              name: 'Service areas',
              requiredClaims: [CustomClaim.manageLogistics],
              Component: Geofences,
            }),
            new Route({
              name: 'Notifications',
              path: 'notifications',
              requiredClaims: [CustomClaim.manageApi],
              Component: Notifications,
            }),
            new Route({
              exact: true,
              path: 'streaming/dashcam/:driverId',
              hideFromMenu: true,
              requiredClaims: [CustomClaim.manageDrivers],
              Component: Dashcam,
            }),
            new Route({
              name: 'Trip types',
              path: 'trip-types',
              requiredClaims: [CustomClaim.manageFinancial],
              Component: TripTypes,
            }),
            new Route({
              name: 'Gatekeeper',
              path: 'gatekeeper',
              requiredClaims: [CustomClaim.manageApi],
              Component: Gatekeeper,
            }),
            new Route({
              name: 'Zendrive CSV',
              path: 'zendrive',
              requiredClaims: [CustomClaim.manageApi],
              Component: Zendrive,
            }),
          ],
        }),
      ],
    }),
    new Route({
      name: 'Auth',
      path: 'auth',
      Component: FirebaseAuthApp,
    }),
    new Route({
      name: 'Earnings',
      path: 'earnings',
      children: [
        new Route({
          path: 'refresh',
          Component: EarningsRefresh,
        }),
        new Route({
          path: 'return',
          Component: EarningsReturn,
        }),
      ],
    }),
    new Route({
      name: 'Sign in',
      path: 'sign-in',
      Component: SignIn,
    }),
    new Route({
      name: 'Sign up',
      path: 'sign-up',
      Component: SignUp,
    }),
    new Route({
      name: 'Forgot password',
      path: 'forgot-password',
      Component: ForgotPassword,
    }),
    new Route({
      name: 'Create account',
      path: 'driver-sign-up',
      Component: LinkFountainApplication,
    }),
    new Route({
      name: 'Team invite',
      path: 'user-group-invites/:id',
      Component: UserGroupInvite,
    }),
    new Route({
      Component: MainLayout,
      children: [
        new Route({
          name: 'About',
          path: 'about',
          Component: AboutPage,
        }),
        new Route({
          name: 'Privacy Policy',
          path: 'privacy-policy',
          Component: PrivacyPolicy,
        }),
        new Route({
          name: 'Pricing',
          path: 'pricing',
          Component: Pricing,
        }),
        new Route({
          name: 'Terms and Conditions',
          path: 'terms-and-conditions',
          Component: TermsAndConditionsPage,
        }),
        new Route({
          name: 'Share my ride',
          path: 'shareMyRide',
          Component: ShareMyRide,
        }),
        new Route({
          name: 'Credits',
          path: 'credits',
          Component: Credits,
        }),
        new Route({
          name: 'Dispatch portal',
          path: 'dispatch',
          Component: Dispatch,
          children: [
            new Route({
              name: 'Manual entry',
              path: 'create',
              Component: DispatchForm,
            }),
            new Route({
              name: 'History',
              path: 'history',
              Component: History,
            }),
            new Route({
              name: 'Job details',
              path: 'trips/:customerId/:tripId',
              Component: DispatchTrip,
            }),
            new Route({
              Component: ActiveDispatch,
            }),
          ],
        }),
        new Route({
          name: 'Vendors',
          path: 'vendors',
          Component: CreateVendor,
        }),
        // new Route({
        //   name: 'WaveCoin',
        //   path: 'wave-coin',
        //   Component: Crypto,
        // }),
        new Route({
          name: 'Openings',
          path: 'openings',
          Component: Openings,
        }),
        new Route({
          name: 'My profile',
          path: 'profile',
          Component: Profile,
        }),
        new Route({
          name: 'Catering Delivery',
          path: 'catering-delivery',
          Component: CateringDelivery, // New Route for Catering Delivery
        }),
        new Route({
          name: 'Rideshare Services',
          path: 'rideshare-services',
          Component: RideshareLeadGen,
        }),
        new Route({
          path: 'jobs',
          children: [
            // new Route({
            //   name: 'Where can we pick you up?',
            //   path: 'location',
            //   Component: LocationPermission,
            // }),
            new Route({
              name: 'Ride now',
              path: 'create',
              Component: RideForm,
            }),
            // new Route({
            //   name: 'Book a ride',
            //   path: 'create-old',
            //   Component: CreateJob,
            // }),
            new Route({
              name: 'Job history',
              path: 'history',
              Component: JobHistory,
            }),
            new Route({
              name: 'Chat',
              path: ':customerId/:tripId/chat',
              Component: Chat,
            }),
            new Route({
              name: 'Job details',
              path: ':customerId/:tripId',
              Component: Job,
            }),
            new Route({
              name: 'My jobs',
              Component: Jobs,
            }),
          ],
        }),
        new Route({
          exact: true,
          name: 'Home',
          Component: Home,
        }),
      ],
    }),
  ],
});

export default root;
